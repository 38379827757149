import ES from "./dictionaries/es";
import EN from "./dictionaries/en";

const VAR_REGEX = /%[A-Za-z0-9-_ ]*%/g;

const dictionaries = {
    'es': ES,
    'en': EN,
}

const hasVariables = (string) => {
    return [...Array.from(string.matchAll(VAR_REGEX))].length!==0;
}

export const getTranslation = (key, vars = {}, lang) => {
    const dictionary = dictionaries[lang];

    if(!dictionary[key]){ 
        console.info("TRANSLATION NOT FOUND FOR: ", `'${key}'`);
        return `**${key}**`;
    }

    if(!hasVariables(dictionary[key])) return dictionary[key];

    return dictionary[key].replaceAll(VAR_REGEX, (match)=> vars[match.replaceAll('%','')] ?? '');
}

export const processTranslations = (value, langKey) => {
    return value[langKey];
}