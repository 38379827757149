
import { useContext } from 'react'
import { I18nContext } from './provider';


const useI18n = () => {
    const { translate, translateObject, selectedLang, setSelectedLang } = useContext(I18nContext);

    return { translate, translateObject, selectedLang, setSelectedLang };
}


export default useI18n;