const EN = {
  'Learn More': 'Learn More',
  'I Want This': 'I Want This',

  'Home': 'Home',
  'Products': 'Products',
  'About Us': 'About Us',
  'Digital Menu': 'Digital Menu',
  'Manager Site': 'Manager Site',
  'Reservations': 'Reservations',
  'Survey Creator': 'Survey Creator',
  'Dotell': 'Dotell',
  'Dashboard': 'Dashboard',
  'TaskMe': 'TaskMe',
  'Admin Site': 'Admin Site',
  'Appsinti': 'Appsinti',

  'Privacy Policy': 'Privacy Policy',
  'Terms And Conditions': 'Terms And Conditions',
  'Get Started': 'Get Started',
};

export default EN;
