import React, { useState, useEffect } from 'react';
import { processTranslations, getTranslation } from './helper';

export const I18nContext = React.createContext({
    selectedLang: 'es',
    setSelectedLang: (lang) => console.log(lang),
    translateObject: (value, lang) => `**${value}**`,
    translate: (value, vars) => `**${value}**`
});

const I18nProvider = ({ children }) => {
    const [selectedLang, setSelectedLang] = useState('es');

    useEffect(() => {
        const langFromStorage = localStorage.getItem('lang-code');
        const initLang = langFromStorage ? langFromStorage : 'en';
        setSelectedLang(initLang);
    }, []);

    const values = {
        selectedLang: selectedLang,
        setSelectedLang: (lang) => {
            setSelectedLang(lang);
            localStorage.setItem('lang-code', lang);
        },
        translateObject: (value, lang ) => processTranslations(value, (!!lang ? lang : selectedLang)),
        translate: (value, vars) => getTranslation(value, vars, selectedLang),
    };

    return (<I18nContext.Provider value={values}>{children}</I18nContext.Provider>);
};

export default I18nProvider;