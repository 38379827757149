const ES = {
  'Learn More': 'Ver más',
  'I Want This': 'Lo quiero ahora',

  'Home': 'Inicio',
  'Products': 'Productos',
  'About Us': 'Nosotros',
  'Digital Menu': 'Digital Menu',
  'Manager Site': 'Manager Site',
  'Reservations': 'Reservations',
  'Survey Creator': 'Survey Creator',
  'Dotell': 'Dotell',
  'Dashboard': 'Dashboard',
  'TaskMe': 'TaskMe',
  'Admin Site': 'Admin Site',
  'Appsinti': 'Appsinti',

  'Privacy Policy': 'Políticas de privacidad',
  'Terms And Conditions': 'Términos y Condiciones',
  'Get Started': 'Empezar',
};

export default ES;
